import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"



const ApproachPage = () => (
    <Layout>
    <Seo title="Home" />
        <div className="w-full min-h-full md:w-11/12 lg:w-10/12 xl:w-9/12 mx-auto font-['Inter'] mb-20">
            <div className="grid grid-cols-12 gap-0 ">
                <div className="col-span-12 mt-20 md:mt-28 flex justify-center items-center p-4">
                    <div className="w-10/12 md:w-12/12 text-4xl md:text-4xl xl:text-5xl text-center font-medium text-[#08408F] tracking-tight">
                    Your Product’s Success Matters A Lot to Us.
                    </div>
                </div>

                <div className="col-span-12 md:col-span-7 flex justify-start mt-20 p-4 md:p-8 h-auto ">
                    <div className="my-10">
                    <StaticImage src="../images/approach-success.svg" alt="Stage of product" width={800}/>
                    </div>
                </div>
                <div className="col-span-12 md:col-span-5 mt:0 md:mt-2 flex justify-center items-center ">
                    <div className="w-10/12 md:w-12/12 mx-auto mt-2">
                        <div className=" text-base text-left mt-8 md:mt-6 text-zinc-900 font-normal leading-8 tracking-wide">
                        To transform your idea to product in action is ofcourse our main objective. But more important than that is your product success. We are invested in your project and product success the moment you bring us on board.
                        </div>
                     </div>
                </div>
                <div className="col-start-1 md:col-start-7 col-span-12 md:col-span-5 p-7 md:p-1  ">
                    <div className=" text-base text-left  text-zinc-900 font-normal leading-8 tracking-wide">
                        <div className="mt-1"> Every project brings something new and we treat is as a new assignment rather than following a standard technology implementation. We keep a tab on tech trends and we don’t use the golden hammer approach. When there is a requirement we bring a fresher, newer, economical and better approach to dealing with the same old problem so that our clients get better ROI and success with their product. 
                        </div>      
                                  
                    </div>
                </div>

              
                
{/* start of approach  */}
                <div className="col-span-12 mt-20">

            <div className="grid grid-cols-12 gap-4 p-10 bg-blue-50">

                <div className="col-span-12">
                    <div className="w-10/12 md:w-10/12 p-2 text-2xl md:text-2xl mt-20 font-medium text-[#08408F] tracking-tight">
                        Product Approach
                        <div className="h-6 w-36 bg-blue-800 my-4">

                        </div>
                    </div>
                </div>

                    <div className="col-span-12 md:col-start-1 md:col-span-6 flex justify-center items-center ">
                        <div className="bg-white h-72 w-72 px-6 py-2 border-4 border-blue-300 rounded-full">
                                <div className="h-10 w-10 p-2 rounded-lg bg-white text-center shadow-inner border-2 border-rose-200 text-rose-600 ">
                                    01
                                </div>
                        
                            <div className="h-56 w-56 rounded-lg drop-shadow-lg transition ease-in-out delay-150 scale-100 hover:scale-150 hover:bg-white duration-300">
                            <div className="text-slate-800 font-semibold text-2xl p-2 ">
                                    <div className=" underline underline-offset-8 decoration-rose-500">
                                    ANALYSIS
                                    </div>
                                    <div className="list-none text-xs font-normal space-y-4 mt-4">
                                    <li>Requirement Gathering</li>
                                    <li>Product Planning </li>
                                    <li>Tech Stack Assessment</li>
                                    <li>Resource Allocation </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    

                    <div className="col-span-12 md:col-start-7 md:col-span-6 flex justify-center items-center ">
                        <div className="bg-white h-72 w-72 px-6 py-2 border-4 border-blue-400 rounded-full">
                                <div className="h-10 w-10 p-2 rounded-lg bg-white text-center shadow-inner border-2 border-fuchsia-200 text-fuchsia-600">
                                    02
                                </div>
                        
                            <div className="h-56 w-56 rounded-lg drop-shadow-lg transition ease-in-out delay-150 scale-100 hover:scale-150 hover:bg-white duration-300">
                            <div className="text-slate-800 font-semibold text-2xl p-2">
                                <div className=" underline underline-offset-8 decoration-fuchsia-500">
                                 DESIGN
                                </div>
                                    <div className="list-none text-xs font-normal space-y-4 mt-4 ">
                                    <li>Design System Application </li>    
                                    <li>Wireframe Design</li>
                                    <li>Define Style Guide</li>
                                    <li>Illustrations & Assets Design </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="col-span-12 md:col-start-1 md:col-span-6 flex justify-center items-center ">
                        <div className="bg-white h-72 w-72 px-6 py-2 border-4 border-blue-500 rounded-full">
                                <div className="h-10 w-10 p-2 rounded-lg bg-white text-center shadow-inner border-2 border-sky-200 text-sky-600">
                                    03
                                </div>
                        
                            <div className="h-56 w-56 rounded-lg drop-shadow-lg transition ease-in-out delay-150 scale-100 hover:scale-150 hover:bg-white duration-300">
                            <div className="text-slate-800 font-semibold text-2xl p-2 ">
                                <div className=" underline underline-offset-8 decoration-sky-500">
                                 DEVELOPMENT
                                </div>
                                    <div className="list-none text-xs font-normal space-y-4 mt-4">
                                    <li>Application Architecture </li> 
                                    <li>Database Development </li>   
                                    <li>API Design and Development</li>
                                    <li>Front-end Development </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="col-span-12 md:col-start-7 md:col-span-6 flex justify-center items-center ">
                        <div className="bg-white h-72 w-72 px-6 py-2 border-4 border-blue-600 rounded-full">
                                <div className="h-10 w-10 p-2 rounded-lg bg-white text-center shadow-inner border-2 border-emerald-200 text-emerald-600">
                                    04
                                </div>
                        
                            <div className="h-56 w-56 rounded-lg drop-shadow-lg transition ease-in-out delay-150 scale-100 hover:scale-150 hover:bg-white duration-300">
                            <div className="text-slate-800 font-semibold text-2xl p-2 ">
                                <div className=" underline underline-offset-8 decoration-emerald-500">
                                 LAUNCH
                                </div>
                                    <div className="list-none text-xs font-normal space-y-4 mt-4">
                                    <li>Cloud Deployment </li>
                                    <li>Performance Monitoring </li>
                                    <li>Log Management </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



            </div>
    </div> 

{/* end of approach  */}

{/* start of Remote Engineering Approach  */}
          
                <div className="col-span-12 mt-20">

                    <div className="grid grid-cols-12 gap-4 p-10 bg-blue-50">

                    <div className="col-span-12">
                    <div className="w-10/12 md:w-10/12 p-2 text-2xl md:text-2xl mt-20 font-medium text-[#08408F] tracking-tight">
                        Remote Engineering Approach
                        <div className="h-6 w-48 bg-blue-800 my-4">

                        </div>
                    </div>
                </div>

                        <div className="col-span-12 md:col-start-1 md:col-span-6 flex justify-center items-center ">
                            <div className="bg-white h-72 w-72 px-6 py-2 border-4 border-sky-300 rounded-full">
                                    <div className="h-10 w-10 p-2 rounded-lg bg-white text-center shadow-inner border-2 border-indigo-200 text-indigo-600">
                                        01
                                    </div>
                            
                                <div className="h-56 w-56 rounded-lg drop-shadow-lg transition ease-in-out delay-150 scale-100 hover:scale-150 hover:bg-white duration-300">
                                <div className="slate-800 font-semibold text-2xl p-2 ">
                                        <div className=" underline underline-offset-8 decoration-indigo-500">
                                        Connect
                                        </div>
                                        <div className="list-none text-xs font-normal space-y-4 mt-4">
                                        <li>Breif us about your requirement.</li>
                                        <li>We answer your questions. </li>
                                        <li>Get your quote.</li>
                                        <li>A day to 2 weeks to get profiles.</li>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-span-12 md:col-start-7 md:col-span-6 flex justify-center items-center ">
                                <div className="bg-white h-72 w-72 px-6 py-2 border-4 border-sky-400 rounded-full">
                                        <div className="h-10 w-10 p-2 rounded-lg bg-white text-center shadow-inner border-2 border-amber-200 text-amber-600">
                                            02
                                        </div>
                                
                                    <div className="h-56 w-56 rounded-lg drop-shadow-lg transition ease-in-out delay-150 scale-100 hover:scale-150 hover:bg-white duration-300">
                                    <div className="slate-800 font-semibold text-2xl p-2 ">
                                        <div className=" underline underline-offset-8 decoration-amber-500">
                                        Select
                                        </div>
                                            <div className="list-none text-xs font-normal space-y-4 mt-4">
                                            <li>Finalize your dedicated resource. </li>    
                                            <li>Onboarding.</li>
                                            <li>Meet your developer.</li>
                                            <li>Project specific training.</li>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>


                        <div className="col-span-12 md:col-start-1 md:col-span-6 flex justify-center items-center ">
                                <div className="bg-white h-72 w-72 px-6 py-2 border-4 border-sky-500 rounded-full">
                                        <div className="h-10 w-10 p-2 rounded-lg bg-white text-center shadow-inner border-2 border-lime-200 text-lime-600">
                                            03
                                        </div>
                                
                                    <div className="h-56 w-56 rounded-lg drop-shadow-lg transition ease-in-out delay-150 scale-100 hover:scale-150 hover:bg-white duration-300">
                                    <div className="slate-800 font-semibold text-2xl p-2 ">
                                        <div className=" underline underline-offset-8 decoration-lime-500">
                                        Kick Start
                                        </div>
                                            <div className="list-none text-xs font-normal space-y-4 mt-4">
                                            <li>Start development. </li> 
                                            <li>Scale up or scale down resources. </li>   
                                            <li>Communication</li>
                                            <li>Delivery team backed developers. </li>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                            

                        
                        <div className="col-span-12 md:col-start-7 md:col-span-6 flex justify-center items-center ">
                                <div className="bg-white h-72 w-72 px-6 py-2 border-4 border-sky-600 rounded-full">
                                        <div className="h-10 w-10 p-2 rounded-lg bg-white text-center shadow-inner border-2 border-teal-200 text-teal-600">
                                            04
                                        </div>
                                
                                    <div className="h-56 w-56 rounded-lg drop-shadow-lg transition ease-in-out delay-150 scale-100 hover:scale-150 hover:bg-white duration-300">
                                    <div className="slate-800 font-semibold text-2xl p-2 ">
                                        <div className=" underline underline-offset-8 decoration-teal-500">
                                        Handover
                                        </div>
                                            <div className="list-none text-xs font-normal space-y-4 mt-4">
                                            <li>End of development cycle.</li>
                                            <li>Handover files or code finalizing. </li>
                                            <li>Developer exit.</li>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>

                {/* end of Remote Engineering Approach  */}

            </div>
        </div>
    </Layout>
)

export default ApproachPage